import axios from "axios";

if (process.env.VUE_APP_NAME === undefined) {
	console.error(
		"Environment variable 'VUE_APP_NAME' is not set. It is used to build the app's base URL for the API, i.e. '/VUE_APP_NAME/rest/'. ",
		"You have to set a value to `process.env.VUE_APP_NAME` in your vue.config.js file, and restart."
	);
}

const instance = axios.create({
	baseURL: "/" + process.env.VUE_APP_NAME + "/rest/",
	timeout: 30000,
	withCredentials: true,
	headers: {
		"Content-Type": "application/json",
	},
});

// // configuring each request before executing it
// instance.interceptors.request.use(config => {
// 	// config.headers["Authorization"] = "Bearer " + store.getters[get.TOKEN] // not needed when we use cookies
// 	return config
// });

export default instance;
