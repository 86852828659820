<template>
  <v-footer app class="pa-3">
    <div>{{ version }}</div>
    <v-spacer />
    <div>
      <strong>Autopass</strong>
      &copy; {{ new Date().getFullYear() }}
    </div>
  </v-footer>
</template>

<script>
import { mapGetters } from "vuex";
import app from "../components/external/modules/app/store/constants";
import { VFooter, VSpacer } from "vuetify/lib";

export default {
  components: { VFooter, VSpacer },
  computed: {
    ...mapGetters({
      version: app.get.VERSION,
    }),
  },
  created() {
    this.$store
      .dispatch(app.act.FETCH_APP_INFO)
      .then(() => {
        /* nothing much */
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style></style>
