// utility function to set and return the given field value in the local storage
// if no value is provided, then the local storage value is returned
export default function (fieldName, fieldValue) {
	if (fieldValue === undefined) {
		const val = localStorage.getItem(fieldName);
		// console.log('Getting value for "', fieldName, '" = ', val)
		return val;
	}
	// console.log('Setting and returning value for "', fieldName, '" = ', fieldValue)
	localStorage.setItem(fieldName, fieldValue);
	return fieldValue;
}
