export default Object.freeze({

	// action names - let's keep this sorted please
	act: {
		FETCH_CURRENT_USER: "FETCH_CURRENT_USER",
		LOGIN: "LOGIN",
		LOGOUT: "LOGOUT",
	},

	// getter names - let's keep this sorted please
	get: {
		CURRENT_USER: "CURRENT_USER",
		EXPIRATION: "EXPIRATION",
		IS_LOGGED_IN: "IS_LOGGED_IN",
	},

	// mutation names - let's keep this sorted please
	set: {
		AUTH_INFO: "AUTH_INFO",
		CURRENT_USER: "CURRENT_USER",
		LOGOUT: "LOGOUT",
	},

	// useful variables
	var: {
		EXPIRATION: "EXPIRATION",
	}
});