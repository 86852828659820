<template>
  <v-app-bar color="blue darken-4" dark app fixed clipped-left>
    <v-app-bar-nav-icon @click.stop="clickOnMenuIcon"></v-app-bar-nav-icon>

    <img src="@/assets/white.png" class="hamp-toolbar-logo" />

    <v-toolbar-title>
      <router-link :to="homepath" custom v-slot="{ navigate }">
        <span @click="navigate">{{ appName }}</span>
      </router-link>
    </v-toolbar-title>

    <v-spacer></v-spacer>

    <v-toolbar-title v-if="user">
      <router-link :to="accountpath" custom v-slot="{ navigate }">
        <span @click="navigate">{{ firstName }} {{ lastName }}</span>
      </router-link>
    </v-toolbar-title>

    <v-btn v-if="!user" icon @click.stop="login">
      <v-icon>mdi-account-circle</v-icon>
    </v-btn>

    <v-btn v-if="user" icon @click.stop="logout">
      <v-icon>mdi-eject</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
import { mapGetters } from "vuex";
import path from "../shared/paths";
import app from "../components/external/modules/app/store/constants";
import user from "../components/external/modules/user/store/constants";
import {
  VAppBar,
  VAppBarNavIcon,
  VToolbarTitle,
  VBtn,
  VIcon,
  VSpacer,
} from "vuetify/lib";

export default {
  components: {
    VAppBar,
    VAppBarNavIcon,
    VToolbarTitle,
    VBtn,
    VIcon,
    VSpacer,
  },

  data() {
    return {
      homepath: path.HOME,
      accountpath: path.ACCOUNT,
    };
  },

  computed: {
    ...mapGetters({
      user: user.get.CURRENT_USER,
      expiration: user.get.EXPIRATION,
      appName: app.get.APP_NAME,
    }),
    firstName() {
      if (this.user) {
        return this.user.firstName;
      }
      return undefined;
    },
    lastName() {
      if (this.user) {
        return this.user.lastName;
      }
      return undefined;
    },
  },

  methods: {
    clickOnMenuIcon() {
      this.$emit("drawer-toggled");
    },
    login() {
      if (!this.$route.fullPath.startsWith(path.LOGIN)) {
        this.$router.push({ path: path.LOGIN });
      }
    },
    logout() {
      const self = this;
      this.$store.dispatch(user.act.LOGOUT).finally(() => {
        self.$router.push(path.LOGIN);
      });
    },
  },
};
</script>

<style>
.hamp-toolbar-logo {
  width: 25px;
  margin-left: 8px;
  margin-right: 20px;
}
</style>
