<template>
  <!--Absolutely necessary in a vuetify-based app-->
  <v-app>
    <!-- Menu: where we can choose where to go -->
    <!-- NB: we have to put it before the toolbar so it's prettier-->
    <hamp-drawer :hamp-drawer-open="appDrawerOpen" @hamp-menu-off="appDrawerOpen = false" />

    <!-- Toolbar: the bar on top of everything -->
    <hamp-toolbar @drawer-toggled="appDrawerOpen = !appDrawerOpen" />

    <!-- Content: depends on the current route. NB: this is idiomatic vue-router code! -->
    <v-main>
      <router-view />
    </v-main>

    <!-- Footer -->
    <hamp-footer />
  </v-app>
</template>

<script>
import { VApp, VMain } from "vuetify/lib";
import hampDrawer from "./HampDrawer.vue";
import hampToolbar from "./HampToolbar.vue";
import hampFooter from "./HampFooter.vue";
// import { mapGetters } from "vuex";
// import { get } from "@/store/constants";
// import path from "../path";

export default {
  //   // name: 'App',
  components: {
    VApp,
    VMain,
    hampDrawer,
    hampToolbar,
    hampFooter,
  },
  //   computed: {
  //     ...mapGetters({
  //       user: get.CURRENT_USER
  //     })
  //   },
  data() {
    return {
      appDrawerOpen: false,
    };
  },
  //   watch: {
  //     // if the user info becomes unavailable, then we show the login page
  //     user (newVal, oldVal) {
  //       if (newVal == null && oldVal != null) {
  //         console.log('User', oldVal.firstName, '', oldVal.lastName, 'is not logged in anymore!')
  //         if (!this.$route.fullPath.startsWith(path.LOGIN)) {
  //           this.$router.push({ path: path.LOGIN, query: { next: this.$route.fullPath } })
  //         }
  //       }
  //     }
  //   }
};
</script>
