import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

// modules
import app from "../components/external/modules/app/store";
import user from "../components/external/modules/user/store";

export default new Vuex.Store({
	state: {},
	mutations: {},
	actions: {},
	modules: {
		app,
		user,
	},
});
