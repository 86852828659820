import app from "./constants";
import api from "../../shared/utils/api";

export default {
	// ---
	[app.act.FETCH_APP_INFO]({ commit }) {
		return api
			.get("appadmin/read")
			.then((res) => {
				if (res.data.status === 200) {
					commit(app.set.APP_INFO, res.data.entity);
				}
				// TODO handle issue - snackbar
			})
			.catch((error) => {
				// TODO handle issue - snackbar
				console.log(error);
			});
	},
};
