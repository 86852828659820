import app from "./constants";

export default {
	// ---
	[app.get.APP_NAME]: (state) => {
		return state.appName;
	},

	// ---
	[app.get.VERSION]: (state) => {
		return state.version;
	},
};
