import api from "../../shared/utils/api";
import authLevels from "../../shared/utils/authlevels";
import axios from "axios";
import Base64 from "Base64";
import user from "./constants";

export default {
	// ---
	[user.act.FETCH_CURRENT_USER]({ commit, state }, force) {
		if (!state.currentUser || force) {
			return api
				.get("user/current")
				.then((res) => {
					if (res.data.status === 200) {
						// setting the auth level
						const currentUser = res.data.entity;
						if (currentUser.admin) {
							currentUser.authLevel = authLevels.ADMIN;
						} else if (currentUser.superUser) {
							currentUser.authLevel = authLevels.SUPER;
						}

						// setting the current user in the store
						commit(user.set.CURRENT_USER, res.data.entity);
					}
					// TODO handle issue - snackbar
				})
				.catch((error) => {
					// TODO handle issue - snackbar
					console.log(error);
				});
		}
	},

	// ---
	[user.act.LOGIN]({ commit }, payload) {
		return axios
			.post(
				process.env.VUE_APP_NAME + "/sessionauth/login",
				{},
				{
					params: { grant_type: "client_credentials" },
					headers: {
						Authorization:
							"Basic " + Base64.btoa(payload.email + ":" + payload.password),
					},
					withCredentials: true,
				}
			)
			.then((res) => {
				// the request went through, and the user got a cookie !
				if (res.status === 200 && res.data !== null) {
					commit(user.set.AUTH_INFO, { expiration: res.data.expires });
					return Promise.resolve();
				} else {
					// no auth failed for,this user (bad mail or password)
					// TODO replace by message to snackbar
					console.log(res);
					return Promise.reject(new Error("Cannot authenticate user"));
				}
			})
			.catch((err) => {
				console.log(err);
				// the request failed technically
				// TODO replace by message to snackbar
				return Promise.reject(err);
			});
	},

	// ---
	[user.act.LOGOUT]({ commit }) {
		return axios
			.post(process.env.VUE_APP_NAME + "/sessionauth/logout", {}, {})
			.catch((err) => {
				console.log(err);
				// the request failed technically
				// TODO replace by message to snackbar
				return Promise.reject(err);
			})
			.finally(() => {
				commit(user.set.LOGOUT);
			});
	},
};
