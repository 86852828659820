// this module's different parts
import actions from "./actions.js";
import mutations from "./mutations.js";
import getters from "./getters.js";

// this module's state
const state = {
	appName: null,
	version: null,
};

export default {
	// namespacing is generally a good idea, but prevents us from using this state in generic components, 
	// since we cannot know in advance how a developer will name it when (s)he adds this to his.er global store
	namespaced: false,
	state,
	actions,
	mutations,
	getters,
};
