import user from "./constants";
import store from "../../../../../store";

export default {
	// ---
	[user.get.CURRENT_USER]: (state) => {
		return state.currentUser;
	},

	// ---
	[user.get.EXPIRATION]: (state) => {
		return state.expiration;
	},

	// ---
	[user.get.IS_LOGGED_IN]: (state) => {
		return (when) => {
			console.warn("This getter should not perform dispatches and commits !!");

			// are we logged in ? trying the get the answer from the state
			if (state.currentUser !== null && state.expiration !== null) {
				return state.expiration > when;
			}

			// do we have an expiration date in the local storage ?
			const expirationString = localStorage.getItem(user.var.EXPIRATION);
			if (!expirationString) {
				console.log("Seems like we were not authenticated thus far");
				return false;
			}

			// since the answer is NO, maybe this is because we refreshed ? so let's find out from the local storage
			const expiration = new Date(expirationString);

			// seems like there's a session, which is not expired yet - let's update our state with that for the next time
			if (when > expiration) {
				console.log("Auto-login failed due to expired session!");
				return false;
			}

			// keeping the auth info in our store
			store.commit(user.set.AUTH_INFO, {
				expiration: expiration,
			});

			// computing an expiration time
			const expirationTime = expiration.getTime() - when.getTime();

			// setting the auto-logout
			setTimeout(() => {
				if (store.getters[user.get.IS_LOGGED_IN](new Date())) {
					console.log("Auto-logout: done");
					store.dispatch(user.act.LOGOUT);
				} else {
					console.log("Auto-logout: skipped");
				}
			}, expirationTime - 1000);

			// fetching the current user info
			store.dispatch(user.act.FETCH_CURRENT_USER, true);

			// we're logged in!
			return true;
		};
	},
};
