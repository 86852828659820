export default Object.freeze({
	// action names - let's keep this sorted please
	act: {
		FETCH_APP_INFO: "FETCH_APP_INFO",
	},

	// getter names - let's keep this sorted please
	get: {
		APP_NAME: "APP_NAME",
		VERSION: "VERSION",
	},

	// mutation names - let's keep this sorted please
	set: {
		APP_INFO: "APP_INFO",
	},
});
