import user from "./constants";

export default {
	// ---
	[user.set.AUTH_INFO](state, authInfo) {
		state.expiration = Date.parse(authInfo.expiration);
		localStorage.setItem(user.var.EXPIRATION, authInfo.expiration);
	},

	// ---
	[user.set.CURRENT_USER](state, user) {
		state.currentUser = user;
	},

	// ---
	[user.set.LOGOUT](state) {
		state.expiration = null;
		localStorage.removeItem(user.var.EXPIRATION);
		state.currentUser = null;
		console.log("All auth infos removed!");
	},
};
