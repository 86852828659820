<template>
  <w-drawer
    :groups="groups"
    :width="300"
    :is-open="hampDrawerOpen"
    @drawer-off="$emit('hamp-drawer-off')"
  />
</template>

<script>
//   The Menu component should always :
//   - be passed an array of groups
//   - tell if the menu is open or not through the bound (with ':') prop 'is-open'
//   - handle (with '@') the 'drawer-off' event

import WDrawer from "../components/external/modules/shared/views/WDrawer.vue";
import path from "../shared/paths";

export default {
  components: { WDrawer },
  props: {
    hampDrawerOpen: { type: Boolean, default: false },
  },
  data() {
    return {
      groups: [
        {
          title: "Home",
          icon: "mdi-home",
          to: path.HOME,
        },
        {
          title: "Account",
          icon: "mdi-account-box",
          items: [
            { title: "Login", to: path.LOGIN },
            { title: "My account", to: path.ACCOUNT },
          ],
        },
        {
          title: "Operations",
          icon: "mdi-clipboard-text",
          items: [{ title: "Orders", to: path.ORDERS }],
        },
        {
          title: "Documentation",
          icon: "mdi-code-tags",
          items: [
            { title: "Developer doc", to: path.DOCDEV },
            { title: "API doc", to: path.DOCAPI },
          ],
        },
        {
          title: "Admin",
          icon: "mdi-lock",
          items: [{ title: "Jobs", to: path.JOBS }],
        },
      ],
    };
  },
};
</script>

<style></style>
