export default {
	HOME: "/home",
	LOGIN: "/login",
	ACCOUNT: "/account",
	DOCAPI: "/docapi",
	DOCDEV: "/docdev",
	JOBS: "/jobs",
	ORDER: "/orders/:which",
	ORDERS: "/orders",
	ORDERITEM: "/orderitem/:which",
};
